import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Scripts from './Scripts';
import Event, { EVENT_GTM_PURCHASE } from '@scandipwa/gtm/src/util/Event';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';

export const HR_INJECTION_TIMEOUT = 500;
export const HR_PAGELOAD_TIMEOUT = 1000;

export class HelloRetail extends PureComponent {
    static propTypes = {
        enabled: PropTypes.bool,
        partner_id: PropTypes.string
    }

    initialize() {
        const { enabled } = this.props;

        if (this.enabled || !enabled) {
            return;
        }
        this.enabled = true;
        this.injectScripts();
        this.bindEvents();
        this.resetBody();
    }

    resetBody(){
        setInterval(() => {
            var target = document.getElementsByTagName('BODY')[0];
            target.style = null;
        }, 500);
    }

    componentDidMount() {
        this.initialize();
    }

    bindEvents() {
        this.listener = Event.observer(EVENT_GTM_PURCHASE, ({ orderID: orderId, totals }) => {
            this.dispatchPurchaseEvent(
                orderId,
                totals
            );
        });
    }

    dispatchPurchaseEvent(orderId, totals) {
        const { items } = totals;
        const products = items.map(i => i.sku);
        const { email: stateEmail, customer: { email: customerEmail} } = this.props;
        const email = stateEmail || customerEmail || BrowserDatabase.getItem('HRCONVERSIONEMAIL');
        BrowserDatabase.deleteItem('HRCONVERSIONEMAIL');

        _awev = (window._awev || []); _awev.push(["bind_once", "context_ready", function () {
            ADDWISH_PARTNER_NS.api.conversion.track_sale({
                total: totals.subtotal_incl_tax,
                orderNumber: orderId,
                email: email,
                productNumbers: products
            });
        }]);
    }

    componentDidUpdate(prevProps) {
        this.initialize();
        const { canonical_url = '' } = this.props;
        const { canonical_url: prevCanonical } = prevProps;
        if (canonical_url && canonical_url != prevCanonical && !canonical_url.endsWith('undefined')) {
            this.reload();
        }

    }

    injectScripts() {
        const { partner_id } = this.props;
        if (!partner_id) return;
        const script = Scripts.getScript(
            partner_id
        );

        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s);
    }

    reload() {
        if (window.ADDWISH_PARTNER_NS) {
            window.ADDWISH_PARTNER_NS.api.reload();
            // setTimeout(() => {
            //     window.ADDWISH_PARTNER_NS.api.reload();
            // }, HR_PAGELOAD_TIMEOUT);
        }
    }


    render() {
        return null;
    }
}

export default HelloRetail;
