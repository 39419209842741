import { PureComponent } from 'react';
import { connect } from 'react-redux';

import HelloRetailSearchComponent from './HelloRetailSearch.component';

/** @namespace Hoeks/HelloRetail/Component/HelloRetailSearch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    canonical_url: state.MetaReducer?.canonical_url,
});

/** @namespace Hoeks/HelloRetail/Component/HelloRetailSearch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace Hoeks/HelloRetail/Component/HelloRetailSearch/Container/HelloRetailSearchContainer */
export class HelloRetailSearchContainer extends PureComponent {
    componentDidMount() {
        // this.reload();
    }

    reload() {
        if (window.ADDWISH_PARTNER_NS) {
            console.log("debug hello retail reload search")
            window.ADDWISH_PARTNER_NS.api.reload();
        }
    }

    render() {
        return(
            <HelloRetailSearchComponent 
                {...this.props}
                {...this.state}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelloRetailSearchContainer);